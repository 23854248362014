<template>

  <div>
    <!-- <b-row>
      <b-col
        v-for="(data,index) in solidColor"
        :key="index"
        md="6"
        xl="3"
      >
        <b-link :to="{ name: data.routeToGo }">
          <b-card
            class="cardPointer"
            :bg-variant="data.bg"
            text-variant="white"
          >
            <b-card-title class="text-white">
              {{ data.title }}
            </b-card-title>
            <b-card-text>
              Some quick example text to build on the card title and make up.
            </b-card-text>
            <feather-icon
              icon="ActivityIcon"
            />
          </b-card>
        </b-link>

      </b-col>
    </b-row> -->
    <b-row
      v-for="(section, index) in settings"
      :key="index"
      class="ml-25"
    >
      <b-col
        cols="12"
      >
        <h2 class="mb-50">
          {{ section.title }}
        </h2>
      </b-col>
      <div
        v-for="(sett, index2) in section.setting"
        :key="index2"
      >
        <b-col class="m-0 p-0">
          <b-card
            :bg-variant="sett.bg"
            class="setting-box mr-1 cardPointer"
            @click="routerPush(sett.routeToGo)"
          >
            <b-card-body
              class="d-flex align-items-center flex-column"
            >
              <b-img
                width="40"
                height="40"
                :src="require('@/@core/assets/icone-light-dark/settings/' + sett.icon + '.svg')"
              />
              <h5 class="text-white text-center mt-1">
                {{ sett.title }}
              </h5>
            </b-card-body>
          </b-card>
        </b-col>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCard, BCardBody, BCol, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    // BCardText,
    // BCardTitle,
    BCardBody,
  },
  data() {
    return {
      settings: [
        {
          title: 'Azienda',
          setting: [
            /* {
              bg: 'primary', title: 'Impostazioni Azienda', icon: 'impostazioni-azienda', routeToGo: 'my-organization',
            }, */
            {
              bg: 'primary', title: 'Fisco', icon: 'fisco', routeToGo: 'iva',
            },
            /* {
              bg: 'primary', title: 'Banche', icon: 'banche', routeToGo: 'general-users',
            },
            {
              bg: 'primary', title: 'Privacy', icon: 'privacy', routeToGo: 'general-users',
            },
            {
              bg: 'primary', title: 'Usabilità', icon: 'usabilita', routeToGo: 'general-users',
            },
            {
              bg: 'primary', title: 'Sicurezza', icon: 'security', routeToGo: 'general-users',
            },
            {
              bg: 'primary', title: 'Set Attributi Prodotti/servizi', icon: 'set-attributi', routeToGo: 'set-attributes',
            },
            {
              bg: 'primary', title: 'Attributi', icon: 'attributi', routeToGo: 'general-users',
            }, */
          ],
        },
        {
          title: 'Persone e Azienda',
          setting: [
            {
              bg: 'secondary', title: 'Collaboratori', icon: 'collaboratori', routeToGo: 'general-users',
            },
            /* {
              bg: 'secondary', title: 'Clienti', icon: 'clienti', routeToGo: 'general-users',
            },
            {
              bg: 'secondary', title: 'Fornitori', icon: 'fornitori-dark', routeToGo: 'general-users',
            },
            {
              bg: 'secondary', title: 'Contatti', icon: 'contatti', routeToGo: 'general-users',
            },
            {
              bg: 'secondary', title: 'Venditori', icon: 'venditore', routeToGo: 'general-users',
            }, */
            /* { bg: 'secondary', title: 'quill editor', routeToGo: 'quill-editor' },
            { bg: 'secondary', title: 'Categorie/Catalogo', routeToGo: 'manage-category' },
            { bg: 'secondary', title: 'Punti Vendita', routeToGo: 'general-organization' },
            { bg: 'secondary', title: 'Movimenti Magazzino', routeToGo: 'flow-warehouse' },
            { bg: 'secondary', title: 'Magazzino', routeToGo: 'flow-warehouse' },
            { bg: 'secondary', title: 'Prodotti', routeToGo: 'general-products' },
            { bg: 'secondary', title: 'Servizi', routeToGo: 'general-services' }, */
          ],
        },
        {
          title: 'Prodotti e Servizi',
          setting: [
            {
              bg: 'info', title: 'Catalogo', icon: 'categorie-dark', routeToGo: 'catalogs',
            },
            {
              bg: 'info', title: 'Categorie', icon: 'lotti-seriali-dark', routeToGo: 'categories',
            },
            /* { bg: 'info', title: 'Categorie', routeToGo: 'manage-category' }, */
            {
              bg: 'info', title: 'Prodotti', icon: 'prodotti-dark', routeToGo: 'products',
            },
            {
              bg: 'info', title: 'Listini', icon: 'listini-dark', routeToGo: 'prices-list',
            },
            {
              bg: 'info', title: 'Importa Prodotti-Prezzi', icon: 'prodotti-dark', routeToGo: 'import-products',
            },
            {
              bg: 'info', title: 'Dizionari', icon: 'prodotti-dark', routeToGo: 'diz',
            },
            {
              bg: 'info', title: 'Lotti Seriali', icon: 'lotti-seriali-dark', routeToGo: 'serial-batches',
            },
          ],
        },
        {
          title: 'Posti e Luoghi',
          setting: [
            {
              bg: 'secondary', title: 'Punti Vendita', icon: 'punti-vendita-dark', routeToGo: 'organization-list',
            },
            {
              bg: 'secondary', title: 'Magazzini', icon: 'magazzini-dark', routeToGo: 'warehouses',
            },
            {
              bg: 'secondary', title: 'Reparti', icon: 'magazzini-dark', routeToGo: 'department',
            },
            /* { bg: 'primary', title: 'quill editor', routeToGo: 'quill-editor' },
            { bg: 'primary', title: 'Set Attributi Prodotti/servizi', routeToGo: 'set-attributes' },
            { bg: 'primary', title: 'Categorie/Catalogo', routeToGo: 'manage-category' }, */
          ],
        },
        {
          title: 'Dispositivi Generici',
          setting: [
            /* {
              bg: 'secondary', title: 'Plu', icon: 'bilancia', routeToGo: 'manage-plu',
            }, */
            {
              bg: 'secondary', title: 'Plu', icon: 'bilancia', routeToGo: 'manage-plu-multiple-association',
            },
            /* {
              bg: 'secondary', title: 'Bilance', icon: 'bilancia', routeToGo: 'manage-devices',
            }, */
            /* {
              bg: 'secondary', title: 'Computer', icon: 'bilancia', routeToGo: 'manage-plu',
            },
            {
              bg: 'secondary', title: 'Printer', icon: 'stampanti', routeToGo: 'manage-plu',
            },
            {
              bg: 'secondary', title: 'Smartphone', icon: 'smartphone', routeToGo: 'manage-plu',
            },
            {
              bg: 'secondary', title: 'Telecamere', icon: 'smartphone', routeToGo: 'manage-plu',
            },
            {
              bg: 'secondary', title: 'Cassa', icon: 'cassa', routeToGo: 'manage-plu',
            }, */
            /* { bg: 'success', title: 'Commercialista', routeToGo: 'general-business' },
            { bg: 'success', title: 'C/C Bancari', routeToGo: 'bank-accounts' },
            { bg: 'success', title: 'Aliquota IVA', routeToGo: 'vat' }, */
          ],
        },
        /* {
          title: 'Documenti',
          setting: [
            { bg: 'secondary', title: 'Contabili', routeToGo: 'invoice-list' },
            { bg: 'secondary', title: 'Fiscali', routeToGo: 'general-documentations' },
            { bg: 'secondary', title: 'Procedure', routeToGo: 'general-documentations' },
            { bg: 'secondary', title: 'Progetti', routeToGo: 'general-documentations' },
            { bg: 'secondary', title: 'Medici', routeToGo: 'general-documentations' },
            { bg: 'secondary', title: 'Speciali', routeToGo: 'general-policy' },
          ],
        }, */
      ],
      solidColor: [
        { bg: 'primary', title: 'Gestione Collaboratori/Utenti', routeToGo: 'general-users' },
        { bg: 'primary', title: 'quill editor', routeToGo: 'quill-editor' },
        { bg: 'primary', title: 'Set Attributi Prodotti/servizi', routeToGo: 'set-attributes' },
        { bg: 'primary', title: 'Gestione Categorie/Catalogo', routeToGo: 'manage-category' },
        { bg: 'secondary', title: 'Punti Vendita', routeToGo: 'general-organization' },
        { bg: 'success', title: 'Movimenti Magazzino', routeToGo: 'flow-warehouse' },
        { bg: 'success', title: 'Gestione Magazzino', routeToGo: 'flow-warehouse' },
        { bg: 'warning', title: 'Gestione Prodotti', routeToGo: 'general-products' },
        { bg: 'info', title: 'Gestione Servizi', routeToGo: 'general-services' },
        { bg: 'info', title: 'Gestione Dispositivi', routeToGo: 'manage-devices' },
        { bg: 'info', title: 'Gestione Plu', routeToGo: 'manage-plu' },
        { bg: 'info', title: 'Gestione Email', routeToGo: 'configuration-email' },
        { bg: 'info', title: 'Preferenze', routeToGo: 'preferences' },
        { bg: 'info', title: 'Integrazioni', routeToGo: 'integrations' },
        { bg: 'info', title: 'Collaboratori', routeToGo: 'collaborators' },
        { bg: 'success', title: 'Gestione Commercialista', routeToGo: 'general-business' },
        { bg: 'success', title: 'C/C Bancari', routeToGo: 'bank-accounts' },
        { bg: 'success', title: 'Aliquota IVA', routeToGo: 'vat' },
        { bg: 'secondary', title: 'Modelli Documentali', routeToGo: 'general-documentations' },
        { bg: 'primary', title: 'Gestione DVR / GDPR', routeToGo: 'general-policy' },
        /*  { bg: 'secondary', title: 'Modelli Documentali', routeToGo: 'general-documentations' },
        { bg: 'success', title: 'Gestione Commercialista', routeToGo: 'general-business' },
        { bg: 'warning', title: 'Gestione Ricoveri', routeToGo: 'general-shelter' },
        { bg: 'danger', title: 'Gestione Vaccinazioni', routeToGo: 'general-vaccinations' },
        { bg: 'primary', title: 'Gestione Farmaci', routeToGo: 'general-medications' },
        { bg: 'secondary', title: 'Gestione Parafarmaci', routeToGo: 'general-parapharmaceuticals' },
        { bg: 'success', title: 'Gestione Mangimi', routeToGo: 'general-feed' },
        { bg: 'danger', title: 'Gestione Accessori', routeToGo: 'general-accessory' },
        { bg: 'warning', title: 'Gestione Materiali di consumo', routeToGo: 'general-consumables' },
        { bg: 'info', title: 'Configurazione Analisi', routeToGo: 'general-analysis' }, */
      ],
    }
  },
  methods: {
    routerPush(name) {
      this.$router.push({ name })
    },
  },
}
</script>
<style scoped>
  .cardPointer{
    position: relative;
    top: 0;
    transition: top ease 0.5s;
  }
  .cardPointer:hover{
    cursor: pointer;
    top: -10px;

  }
  .setting-box{
    width: 150px;
    min-width: 150px;
    min-height: 150px !important;
    height: 150px !important;
  }

   @media only screen and (min-width: 200px) and (max-width: 767px)  {
      .card-body {
        padding: 1rem;
      }
      .setting-box img {
        width: 20px;
        height: 20px;
        margin: 0;
      }
      .setting-box h5 {
        font-size: 10px !important;
      }

      .setting-box{
        width: 100px;
        min-width: 100px;
        min-height: 100px !important;
        height: 100px !important;
      }
    }
</style>
